$primary: #2400FF; 
$secondary:#058060;
$dark: #000000;
$medlight: #999999;
$light: #D9D9D9;
$success: #2aaf71;
$warning: #FFE44F;
$extralight: #cccccc;
$body-color: #000000;
$info-light:#E9E5FF;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "warning":    $warning,
  "light":      $light,
  "dark":       $dark,
  "danger": #dc3545,
  "success": $success,
  "yellow": #F4B81C,
  'info': #A799FF,
  "info-light": $info-light,
);

$font-size-base: 1rem;
$container-max-widths: ( xxl: 1440px );
// $btn-border-width : 0;
$border-radius:.625rem;
$border-radius-lg:.625rem; 
$h1-font-size: $font-size-base * 3;
$input-padding-y: 0.75rem;
$input-font-size:0.875rem; 
$input-border-color: $light;
$form-label-color: $medlight;
$font-weight-semibold: 500;

$min-contrast-ratio:   1.5 !default;
$headings-line-height: 1.5;
$enable-negative-margins: true; 
$breadcrumb-item-padding-x:         1rem;
$breadcrumb-margin-bottom:          1.875rem;
$breadcrumb-divider-color:          $dark;
$btn-padding-y-lg:            0.75rem;
$btn-padding-x-lg:            2rem;
$btn-font-size-lg:            1rem;
$btn-font-size:            0.75rem;
$border-color: $extralight;


$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25, //4
  2: $spacer * .5, //8
  3: $spacer, //16
  4: $spacer * 1.5, //24
  5: $spacer * 3, //48
  6: $spacer * .75, //12
  7: $spacer * .875, //14
  8: $spacer * 1.25, //20
  9: $spacer * 2, //32
) !default;

$font-sizes: (
  1: 3rem, //48
  2: 2.25rem, //36
  3: 1.5rem, //24
  4: 1.125rem, //18
  5: 1rem, //16
  7: 0.875rem, //14
  8: 0.75rem, //12
  9: 0.625rem //10
) !default;

$bordercolor : #ECECEC;
$darkblue : #355CEC;
$lightblue : #D9DFF2;
$blurborder : #99ABEE;


$tooltip-font-size:0.75rem;
$tooltip-padding-y:0.5rem;
$tooltip-bg:#355CEC;
$tooltip-arrow-color:#355CEC;
$tooltip-opacity:1;



$input-btn-padding-y:         .65rem !default;
$input-btn-padding-x:         1rem !default;
$btn-padding-y:               .5rem !default;

$box-shadow-sm: 0px 1px 4px rgba(0, 0, 0, 0.1);
:root {
--toastify-color-light: #E7EAF3;
--toastify-toast-min-height: 60px;
--toastify-text-color-light: #777777;

}

.navbar-expand-lg .navbar-nav .nav-link { --bs-navbar-nav-link-padding-x : 1rem; }
.nav-link { --bs-nav-link-color: $dark}
.btn-primary { background: linear-gradient(200deg, #2400FF 15.03%, #BC10D5 93.09%); border:0 !important }
.btn-primary:hover { background: linear-gradient(228.47deg, #BC10D5 15.03%, #2400FF 93.09%);}
.btn.btn-primary:disabled, .btn.btn-primary.disabled, fieldset:disabled .btn.btn-primary { background: $light;}
.btn-outline-dark { border-color:$light !important; }
