.adminlogin { height: 100vh; display: flex; flex-direction: column; justify-content: center }
.adminwrapper {
    min-height: 100vh;
    .navbar-collapse { flex-grow: 0;}
    .navbar-expand-lg .navbar-nav.dashmenu .nav-link {
        margin: 0 1rem;
        padding-left: 0;
        padding-right: 0;
    }
    .adminprofilepic {
        display: flex; align-items: center;
        span.imgbox { 
            display: block; width: 48px; height: 48px; overflow: hidden; border-radius: 100%; 
            img { width: 100%; height: 100%; object-fit: cover;}
        }
    }
    .dot { display: inline-block; width: 15px; height: 15px; border-radius: 100%; }
}

.slideleft-enter { opacity: 0; transform: translateX(-50px); }
.slideleft-enter-active { opacity: 1; transition: all 0.2s ease-in; transform: translateX(0px); }
.slideleft-exit { opacity: 1; transform: translateX(0px); }
.slideleft-exit-active { opacity: 0; transition: all 0.2s ease-in; transform: translateX(-50px); }

@media only screen and (max-width:1023px) {
    .adminwrapper {
        
    }
}